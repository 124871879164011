
export const AUTH_MAIN_TITLE = `BIGVU, a TV studio in your pocket`;

export const PASSWORD_PATTERN_REGEX = /(?=.*[a-zA-Z])(?=.*\d).*/;

export const DEFAULT_ORGANIZATION_NAME = "bigvu";

export const DEFAULT_PROVIDER = "bigvu";

export const DEFAULT_GRANT_TYPE = "password";

export const LOGIN_PROCESS_FAILURE_URL = "login";

export const STRIPE_SESSION_CREATE_FAILED = "Failed to create a session with the offers provider. Please try again";

export const PASSWORD_VALIDATION_ERROR = "Password should include at least one letter and one number"

export enum FacebookAuthErrorMessages {
    USER_NOT_AUTHORIZED = "User cancelled login or did not fully authorize.",
    GENERAL_ERROR = "Failed to complete the request. Please try again",
}